export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const ACTION_SUCCESS = "ACTION_SUCCESS";
export const SET_MESSAGE = "SET_MESSAGE";

// Temp
export const SET_REGISTRATION_PREFERENCES = "SET_REGISTRATION_PREFERENCES";
